<script>
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import { required } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
import SideButtons from '../../../../../../components/side-buttons.vue';
import cancelation from '../../../../../../components/cancelation.vue';
import { getDropDownElementsByTypeApi } from "@/api/common";
import {printSingleDocumentUrl} from "@/helpers/functions";
import Rooms from "./rooms.vue";
const dt = new Date();
const now =
  dt.getFullYear() +
  "-" +
  (dt.getMonth() + 1 < 10 ? "0" + (dt.getMonth() + 1) : dt.getMonth() + 1) +
  "-" +
  (dt.getDate() < 10 ? "0" + dt.getDate() : dt.getDate());
const after = (value) => value >= now;
const before = (value,vm) => value < vm.data.selectedContract[0].end_date;
const obg = (value) => value != 0;

export default {
  page: {
    title: "Ajout ODS",
    meta: [{ name: "description", content: appConfig.description }],
  },
  import : {printSingleDocumentUrl},
  components: {Rooms, Multiselect, SideButtons },
  props: {
    ODS: {
      type: Array,
      default: undefined,
    },
    total_month: {
      type: Number,
      default: 0,
    },
    disableButtons: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
        operations:[],
        selectedServices:[],
      customDateRangeShortcuts: [
        { key: "thisWeek", label: "Cette semaine", value: "isoWeek" },
        { key: "lastWeek", label: "Semaine dernière", value: "-isoWeek" },
        { key: "last7Days", label: "7 derniers jours", value: 7 },
        { key: "last30Days", label: "30 derniers jours", value: 30 },
        { key: "thisMonth", label: "Ce mois", value: "month" },
        { key: "lastMonth", label: "Dernier mois", value: "-month" },
        { key: "thisYear", label: "Cette année", value: "year" },
        { key: "lastYear", label: "L'année dernière", value: "-year" },
      ],
      tableData: [],
      cancel:false,
      cancelModal:false,
      Cancelation:{},
      getEmit: false,
      print: false,
      add: false,
      save: false,
      saveToPending: false,
      confirm: false,
      validate: false,
      edit: false,
      destroy: false,
      estimated: false,
      title: "Ordre de Service",
      selectedBdv: 0,
      diffDays: 0,
      contracts: [],
      selectedContract: { catering_amnt: 0 },
      ODS_info: {
        type_ODS: "threed_ops",
        Bdv: 0,
        ID_CTT: 0,
        prestataire: 0,
        date_ODS: now,
        date_exec_ODS: now,
        services: [],
        total_amount: 0,
        status: "draft",
        comments: "",
        dateRange: "",
      },
      
      UsedRooms: {},
      send: false,
      disabled: false,
      resetForm: false,
      HostingService: [],
      filteredHostingService: [],
      typeRepas: [],
      have: false,
      bases: [],
      prests: [],
      selectedPrest: "",
      periods: [
        { label: "Periode Fête", value: "F" },
        { label: "Periode Normal", value: "N" },
        { label: "Periode Ramadhan", value: "R" },
      ],
      submitted: false,
      reject: false,
      reset: false,
    };
  },
  validations: {
    ODS_info: {
      Bdv: {
        required,
        obg,
      },
      ID_CTT: {
        required,
        obg,
      },
      prestataire: {
        required,
        obg,
      },
      date_ODS: {
        required,
        after,
      },
      date_exec_ODS: {
        required,
        after,
        // before
      },
    },
  },
  watch: {
    ODS: {
      handler(val) {
        if (val.length <= 0) {
          this.resetData();
          this.have = false;
        }
        else {
          if(this.add || this.edit){
          Swal.fire({
            title: 'Êtes-vous sûr ?',
            text: "Des elements sont en cours de saisie, êtes-vous de quitter ?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Annuler',
            confirmButtonText: 'Oui'
          }).then((result) => {
            if (result.isConfirmed) {
              var _this = this;
              this.resetForm=true;
              setTimeout(function(){ 
                // alert("Hello"); 
                // console.log(val.length);
                _this.ODS_info.id            = val[0].id;
                _this.ODS_info.ref           = val[0].ref;
                _this.ODS_info.uuid           = val[0].uuid;
                _this.ODS_info.Bdv           = val[0].Bdv;
                _this.ODS_info.ID_CTT        = val[0].contract.id;
                _this.ODS_info.prestataire   = val[0].service_provider.id;
                _this.ODS_info.date_ODS      = val[0].date_ODS;
                _this.ODS_info.date_exec_ODS = val[0].date_exec_ODS;
                // _this.ODS_info.period     = val[0].period;
                _this.ODS_info.canceled_at         =  val[0].canceled_at;
                _this.ODS_info.canceled_reason     =  val[0].canceled_reason;
                _this.ODS_info.canceled_by         =  val[0].canceled_by;
                _this.ODS_info.cancel_by           =  val[0].cancel_by;
                _this.ODS_info.status        = val[0].status;
                _this.ODS_info.done                =  val[0].done;
                _this.ODS_info.lifebase      = val[0].lifebase
                _this.ODS_info.total_amount  = val[0].totalAmnt.plain
                _this.ODS_info.items         = val[0].items
                _this.ODS_info.comments      = val[0].comment;
                _this.HostingService         = Object.keys(val[0].items);
                _this.filteredHostingService = val[0].items;
                _this.cancelModal = false;
                _this.cancel = false;
                _this.selectedContract       = val[0].contract;
                _this.selectedBdv            = val[0].lifebase;
                _this.bases.push(_this.selectedBdv);
                _this.selectedPrest          = val[0].service_provider;
                _this.resetForm              = false;
                
                if(val[0].status == 'approved') _this.have = false
                else _this.have  =  true;
              }, 2000,val)
            }
            // else this.resetForm=true;
          })
        }
        else{
            // console.log(val.length);
            this.ODS_info.id            = val[0].id;
            this.ODS_info.ref           = val[0].ref;
            this.ODS_info.uuid          = val[0].uuid;
            this.ODS_info.Bdv           = val[0].Bdv;
            this.ODS_info.ID_CTT        = val[0].contract.id;
            this.ODS_info.prestataire   = val[0].service_provider.id;
            this.ODS_info.date_ODS      = val[0].date_ODS;
            this.ODS_info.date_exec_ODS = val[0].date_exec_ODS;
            // this.ODS_info.period     = val[0].period;
            this.ODS_info.done                =  val[0].done;
            this.ODS_info.canceled_at         =  val[0].canceled_at;
            this.ODS_info.canceled_reason     =  val[0].canceled_reason;
            this.ODS_info.canceled_by         =  val[0].canceled_by;
            this.ODS_info.cancel_by           =  val[0].cancel_by;
            this.ODS_info.comments      = val[0].comment;
            this.ODS_info.status        = val[0].status;
            this.ODS_info.lifebase      = val[0].lifebase;
            this.ODS_info.total_amount  = val[0].totalAmnt.plain;
            this.ODS_info.items         = val[0].items;
            this.HostingService         = Object.keys(val[0].items);
            // console.log(this.HostingService);
            this.filteredHostingService = val[0].items;
            this.HostingService.forEach(element => {
              this.selectedServices.push(this.filteredHostingService[element][0].prestation.threed_service);
            })
            this.cancelModal = false;
            this.cancel = false;

            this.selectedContract = val[0].contract;
            this.selectedBdv = val[0].lifebase;
            this.bases.push(this.selectedBdv);
            this.selectedPrest = val[0].service_provider;
            this.resetForm = false;
            if(val[0].status == 'approved')this.have=false
            else this.have = true;
          }
          
        }

        
        
      },
    },
    add: {
      handler(val) {
        if (val && this.$can('create_threedops_ods')) {
          // console.log("clearForm");
          this.disabled = true;
          this.resetForm = false;
          this.edit = false;
          this.resetData();
        }
        else if (val && !this.$can('create_threedops_ods')) Swal.fire('Accés Refusé','Vous n\'avez pas le droit d\'ajouté','error')
      },
    },
    print:{
      handler(val){
        if(val && this.$can('validate_threedops_ods')) this.printSingleDocument('single_3d_so', this.ODS_info.uuid);
        else if(val && !this.$can('validate_threedops_ods')) Swal.fire('Accés Refusé','Vous n\'avez pas le droit d\'ajouté','error')
      }
    },
    getEmit: {
      handler(val) {
        if (val) {
          // console.log("get emit total");
          this.getTotalAmountODS();
        }
      },
    },
    
    edit: {
      handler(val) {
        if ((val && this.$can('edit_threedops_ods')) || (val && this.ODS_info.status==('pending_confirmation'||'confirmed' || 'approved') && this.$can('override_threedops_ods') )) {
          this.disabled = true;
          this.resetForm = false;
          this.add = false;
        }
        else if (val && !this.$can('edit_threedops_ods')) Swal.fire('Accés Refusé','Vous n\'avez pas le droit d\'ajouté','error')
      },
    },
    cancel:{
      handler(val){
        if( ( val && this.$can('override_threedops_ods') && this.ODS_info.done != 1)){
          this.cancelODS()
        }
        else if(val && !this.$can('override_threedops_ods')) Swal.fire('Accés Refusé','Vous n\'avez pas le droit d\'ajouté','error')
      }
    },
    
    resetForm: {
      handler(val) {
        // console.log(val);
        if (val) {
          // console.log("reset");
          this.resetData();
          this.disabled = false;
          this.saveToPending=false;
          this.have=false;
        }
      },
    },
    
    saveToPending:{
      handler(val){
        if(val && this.canAny(['edit_threedops_ods','create_threedops_ods'])){
          Swal.fire({
            title: 'Êtes vous sûr ?',
            text: "de le mettre en Attente de Confirmation ",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#2b8a3e',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Annuler',
            confirmButtonText: 'Oui',
          }).then((result) => {
            if (result.isConfirmed) {
              // console.log("Save To Pending");
              this.ODS_info.status = "pending_confirmation";
              if(this.ODS_info.id) this.onUpdate();
              else this.onComplete();
            }
          })
        }
        else if(val && !this.canAny(['edit_threedops_ods','create_threedops_ods'])) Swal.fire('Accés Refusé','Vous n\'avez pas le droit d\'ajouté','error')
      }
    },
    confirm:{
      handler(val){
        if(val && this.$can('confirm_threedops_ods')){
          Swal.fire({
            title: 'Êtes vous sûr ?',
            text: "de confirmer cet ODS ?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#2b8a3e',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Annuler',
            confirmButtonText: 'Oui',
          }).then((result) => {
            if (result.isConfirmed) {
              this.onValidate("Confirm");
            }
          })
          
        }
        else if(val && !this.$can('confirm_threedops_ods')) Swal.fire('Accés Refusé','Vous n\'avez pas le droit d\'ajouté','error')
      }
    },
    reject:{
      handler(val){
        if(val && this.$can('confirm_threedops_ods')){
          Swal.fire({
            title: 'Êtes vous sûr ?',
            text: "de rejeter cet ODS ?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#2b8a3e',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Annuler',
            confirmButtonText: 'Oui',
          }).then((result) => {
            if (result.isConfirmed) {
              this.onValidate("Reject");
            }
          })
        }
        else if(val && !this.$can('confirm_threedops_ods')) Swal.fire('Accés Refusé','Vous n\'avez pas le droit d\'ajouté','error')
      }
    },
    validate:{
      handler(val){
        if(val && this.$can('validate_threedops_ods')){
          Swal.fire({
            title: 'Êtes vous sûr ?',
            text: "de Valider cet ODS ? ",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#2b8a3e',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Annuler',
            confirmButtonText: 'Oui',
          }).then((result) => {
            if (result.isConfirmed) {
              this.onValidate("Validate");
            }
          })
          
        }
        else if(val && !this.$can('validate_threedops_ods')) Swal.fire('Accés Refusé','Vous n\'avez pas le droit d\'ajouté','error')
      }
    },
    destroy:{
      handler(val){
        if((val && this.$can('delete_threedops_ods')) || (val && this.ODS_info.status==('pending_confirmation'||'confirmed' || 'approved') && this.$can('override_threedops_ods') )|| (val && this.ODS_info.status==('confirmed' || 'approved') && this.$can('override_threedops_ods') )){this.onDelete(); this.resetForm=false;}
        else if(val && !this.$can('delete_threedops_ods')) Swal.fire('Accés Refusé','Vous n\'avez pas le droit d\'ajouté','error')
        
      }
    }
  },
  mounted() {
    this.ODS_info.date_ODS = this.TodayDate();
    this.ODS_info.date_exec_ODS = this.TodayDate();
    // this.getLifeBases();
    this.getlistPeriod();
    this.getlistContracts();
  },

  methods: {
    printSingleDocument(doc, uid){
      printSingleDocumentUrl(doc, uid);
      this.print =false;
    },
    getlistContracts() {
      this.$http
        .post("/ods/listContractsBytype", { type: "threed_ops" })
        .then((response) => {
          this.contracts = response.data;
          let contracts_list = [];
          response.data.forEach((element) => {
            contracts_list.push(element);
          });
          // contracts_list.unshift({id:0,designation:'Tout',ref:'Tout'})
          this.$emit("getListContracts", contracts_list);
        })
        .catch((error) => console.log(error));
    },
    getLifeBases() {
      this.$http
        .post("/base/lifebases/list")
        .then((response) => {
          this.bases = response.data.original.list;
        })
        .catch((error) => console.log(error));
    },
    onComplete() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        // console.log("ERROR");
        this.save = false;
      } else {
        // console.log("Valid");
        if (!this.ODS_info.id) {
          this.getTotalAmountODS();
        } else if (
          this.ODS_info.id &&
          this.ODS_info.status == ("draft" || "pending_confirmation")
        ) {
          this.getTotalAmountODS();
        }

        // console.log(this.ODS_info);
        this.$http
          .post("/ods/OdsRestauration/store", {
            ods: this.ODS_info,
            items: this.UsedRooms,
          })
          .then((response) => {
            this.send = true;
            this.$emit("odsvalues", response.data);
            Swal.fire("Félicitations!", "ODS " + response.data.ref, "success");
            this.resetData();
            this.$v.$reset();
            this.save = false;
            this.send = false;
            this.disabled = false;
            this.resetForm = true;
            this.saveToPending = false;
          })
          .catch((error) => {
            console.log(error);
            this.send = false;
          });
      }
    },
    onUpdate() {
      // this.submitted = true
      // this.$v.$touch()
      // if (this.$v.$invalid) {
      //     console.log("ERROR");
      //     this.saveToPending=false;
      //     this.edit=false;
      // }
      // else {
      if (
        this.ODS_info.id &&
        this.ODS_info.status == ("draft" || "pending_confirmation")
      ) {
        this.getTotalAmountODS();
      }
      // console.log("Valid");
      this.$http
        .post("/ods/OdsRestauration/update", {
          ods: this.ODS_info,
          items: this.UsedRooms,
        })
        .then((response) => {
          this.send = true;
          this.$emit("odsvalues", response.data);
          if (this.saveToPending) {
            Swal.fire(
              "Félicitations!",
              "ODS " +
                this.ODS_info.ref +
                " a été enregistrer et mis en attente de confirmation",
              "success"
            );
            this.saveToPending = false;
          } else
            Swal.fire(
              "Félicitations!",
              "ODS " + this.ODS_info.ref + " à jour",
              "success"
            );
          this.resetData();
          this.send = false;
          this.saveToPending = false;
          this.edit = false;
          this.disabled = false;
          this.resetForm = true;
        })
        .catch((error) => console.log(error));
      // }
    },
    onValidate(data) {
      this.$http
        .post("/ods/OdsRestauration/validate", {
          ods: this.ODS_info,
          status: data,
        })
        .then((response) => {
          this.send = true;
          this.$emit("odsvalues", response.data);
          if (data == "Confirm")
            Swal.fire(
              "Félicitations!",
              this.ODS_info.ref + " Confirmé",
              "success"
            );
          if (data == "Validate")
            Swal.fire(
              "Félicitations!",
              this.ODS_info.ref + " Approuvé",
              "success"
            );
          if (data == "Reject")
            Swal.fire(
              "Félicitations!",
              this.ODS_info.ref + " Rejeté",
              "success"
            );

          this.resetData();
          this.send = false;
          this.validate = false;
          this.confirm = false;
        })
        .catch((error) => console.log(error));
    },

    onDelete(){
      if((this.ODS_info.status == ('draft' || 'rejected'))|| (this.ODS_info.status==('pending_confirmation'||'confirmed' || 'approved') && this.$can('override_threedops_ods') )){
         var _this = this;
          Swal.fire({
            title: "Êtes-vous sûr de supprimer l'ODS: " + _this.ODS_info.ref + "?",
            text: "Cette Action est irréversible",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor:"#f46a6a",
            cancelButtonColor:  "#34c38f",
            confirmButtonText: 'Oui!',
            cancelButtonText: 'Annuler!',
          }).then((result) => {
            if (result.value) {
              this.$http
                .post("ods/OdsRestauration/delete",{ods:this.ODS_info})
                .then((res) => {
                  // console.log(res);
                  var status = res.data.status;
                  switch (status) {
                    case 200:
                      _this.$toast.success(res.data.msg);
                      this.$emit('odsvalues',res.data);
                      // this.$emit('refresh',true);
                      this.resetData();
                      this.disabled = false;
                      break;

                    case 500:
                      this.$toast.warning(res.data.msg);
                      break;
                  }
                })
                .catch((error) => {
                  _this.$toast.error(error.message);
                })
                .finally(() => {});
            }
          });
          this.destroy=false;
      }
      else this.$toast.warning("Vous ne pouvez pas supprimé un ODS déja confirmé ou approuvé");
    },

    getHostingServices() {
      this.$http
        .post("/ods/OdsRestauration/listRepas", {
          type: this.ODS_info.type_ODS,
          contract_id: this.ODS_info.ID_CTT,
          Bdv: this.ODS_info.Bdv,
          period:this.ODS_info.services
        })
        .then((response) => {
          let List = Object.keys(response.data);
          if(List.length > 0){
            List.forEach(element => {
              this.UsedRooms[element] = undefined;
            })
            this.HostingService = List;
            this.filteredHostingService = response.data;
          }
          else {
            Swal.fire(
              'Vide',
              'Aucune Prestation Trouvé',
              'warning'
            )
          }
          
        })
        .catch((error) => console.log(error));
    },

    getServiceRepas() {
      this.$http
        .post("/ods/OdsRestauration/listRepas", {
          type: this.ODS_info.type_ODS,
          contract_id: this.ODS_info.ID_CTT,
          Bdv: this.ODS_info.Bdv,
          period: this.ODS_info.services,
        })
        .then((response) => {
          let List = Object.keys(response.data);

          this.HostingService = List;
          this.filteredHostingService = response.data;
        })
        .catch((error) => console.log(error));
    },
    getlistPeriod() {
      getDropDownElementsByTypeApi("hosting_service_type")
        .then((res) => {
          // console.log(res);
          this.operations = res.data.original.list;
        })
        .catch((error) => console.log(error));
    },
    
    TodayDate() {
      var td,
        dt = new Date();
      td =
        dt.getFullYear() +
        "-" +
        (dt.getMonth() + 1 < 10
          ? "0" + (dt.getMonth() + 1)
          : dt.getMonth() + 1) +
        "-" +
        (dt.getDate() < 10 ? "0" + dt.getDate() : dt.getDate());

      return td;
    },
    getContractID(contract) {
      this.$emit("currentContract", contract);
      
      this.selectedBdv=[];
      this.ODS_info.ID_CTT = contract.id;
      this.ODS_info.status = "draft";
      this.bases = contract.camps_services.map((element) => {
        return element.camp;
      });
      this.prests.push(contract.supplier);
      this.selectedPrest = contract.supplier;
      if (this.bases.length == 1) {
        this.selectedBdv = this.bases[0];
        this.getLifeBaseID(this.bases[0]);
      }
      this.ODS_info.prestataire = contract.supplier.id;
      this.UsedRooms={};
      // console.log(this.bases);
    },
    getLifeBaseID(lifebase) {
      this.selectedServices=[];
      this.ODS_info.Bdv = lifebase.id;
      this.UsedRooms={};
    },
    getServicesID(service){
      this.ODS_info.services.push(service.id)
      // this.getServiceRepas();
    },
    resetData() {
      // this.disabled=false;
        this.selectedContract = [];
        this.selectedBdv = [];
        this.selectedPrest = [];
        this.bases = [];
        this.prests = [];
        this.send = true;
        this.ODS_info = {
          type_ODS: "threed_ops",
          Bdv: 0,
          ID_CTT: 0,
          prestataire: 0,
          date_ODS: now,
          date_exec_ODS: now,
          services: [],
          total_amount: 0,
          status: "draft",
          comments: "",
          dateRange: "",
        };
      this.HostingService = [];
        this.filteredHostingService = [];
        this.have = false;
      this.estimated = false;
      this.selectedServices=[];
      this.UsedRooms={};
    },
    getTotalAmountODS() {
      var total = 0;
      // let keys = Object.keys(this.UsedRooms);
      // console.log(this.Repas);
      this.HostingService.forEach((element) => {
        if (this.UsedRooms[element]) {
            this.UsedRooms[element].forEach(x => {
            if (x.amount) total +=parseInt(x.amount)
          })
          
          
          // console.log(total);
        }
      });
      // console.log(total);
      if (total) {
        this.ODS_info.total_amount = total;
        this.$emit("totalCurrentOds", total);
        this.getEmit = false;
        return total;
      } else {
        total = 0;
        this.ODS_info.total_amount = 0;
        this.$emit("totalCurrentOds", total);
        this.getEmit = false;
        return total;
      }
    },
    filterRepasPeriod() {
      // console.log(this.ODS_info.period);
      this.getServiceRepas();
    },

    handleUsedRoomsEvent(event){
      // console.log("Event received");
      // console.log(event);
      this.UsedRooms = event;
    },
    reformDat(){
      // console.log(this.ODS_info.date_exec_ODS.split('-'));
      let d1 = new Date(this.ODS_info.date_exec_ODS.split('-')[0],this.ODS_info.date_exec_ODS.split('-')[1]-1,this.ODS_info.date_exec_ODS.split('-')[2]);
      let d2 = new Date(this.ODS_info.date_ODS.split('-')[0],this.ODS_info.date_ODS.split('-')[1]-1,this.ODS_info.date_ODS.split('-')[2]);
      let diff = (d1.getTime() - d2.getTime()) / (1000*3600*24);

      this.diffDays = diff+" jours";
    }
  },
};

</script>

<template>
  <!-- <Layout>
    <PageHeader :title="title" :items="items" /> -->

  <div class="row">
    <div class="col-12">
      <SideButtons
        
        :reset="resetForm"
        :select="have"
        :canEdit="!have"
        :save="send"
        :ODS="true"
        :service_order="ODS_info"
        @info="info = $event"
        @add="add = $event"
        @save="save = $event"
        @saveToPending="saveToPending = $event"
        @edit="edit = $event"
        @SaveEdit="SaveEdit = $event"
        @confirm="confirm = $event"
        @reject="reject = $event"
        @validate="validate = $event"
        @resetEmit="resetForm = $event"
        @delete="destroy = $event"
        @print="print=$event"
      />

      <!-- <b-tabs content-class="mt-0">
          <b-tab title="Informations Générales" active> -->
      <div class="p-3">
        <cancelation v-if="ODS_info.id" :closeModal="cancelModal" :ods="ODS_info" @cancel_reason="Cancelation = $event" @cancel="cancel=$event"></cancelation>
        <div>
          <!-- <h4 class="card-title">New </h4> -->
          
          <div class="row">
            <div class="col-lg-4">
              <div class="form-group">
                <label for="formrow-password-input">N° Contrat</label>
                <multiselect
                  v-model="selectedContract"
                  :select-label="''"
                  :disabled="!disabled"
                  :class="{'bg-light':!disabled,'disabledMS':!disabled}"
                  :value="ODS_info.ID_CTT"
                  @select="getContractID"
                  track-by="id"
                  label="ref"
                  placeholder="Select one"
                  :options="contracts"
                  :searchable="false"
                  :allow-empty="false"
                >
                  <template slot="singleLabel" slot-scope="{ option }"
                    ><strong>{{ option.ref }}</strong></template
                  >
                </multiselect>
                <div v-if="submitted && $v.ODS_info.ID_CTT.$error">
                  <span class="text-danger" v-if="!$v.ODS_info.ID_CTT.obg"
                    >Le Contrat est Obligatoire</span
                  >
                </div>
              </div>
            </div>

            <div class="col-lg-4">
              <div class="form-group">
                <label for="formrow-inputCity">Nom de la Base *</label>
                <multiselect
                  v-model="selectedBdv"
                  :select-label="''"
                  @select="getLifeBaseID"
                  :disabled="bases.length == (1 || 0) || !disabled"
                  :value="ODS_info.Bdv"
                  track-by="id"
                  label="name"
                  :class="{'bg-light':!disabled,'disabledMS':!disabled}"
                  placeholder="Select one"
                  :options="bases"
                  :searchable="false"
                  :allow-empty="false"
                >
                  <template slot="singleLabel" slot-scope="{ option }"
                    ><strong>{{ option.name }}</strong></template
                  >
                </multiselect>
                <div v-if="submitted && $v.ODS_info.Bdv.$error">
                  <span class="text-danger" v-if="!$v.ODS_info.Bdv.obg"
                    >La base de vie est Obligatoire</span
                  >
                </div>
              </div>
            </div>

            <div class="col-lg-4">
              <label for="">Prestataire *</label>
              <multiselect
                v-model="selectedPrest"
                :select-label="''"
                :disabled="true"
                :value="ODS_info.prestataire"
                track-by="id"
                label="prestname"
                :class="{'bg-light':!disabled,'disabledMS':!disabled}"
                placeholder="Select one"
                :options="prests"
                :searchable="false"
                :allow-empty="false"
              >
                <template slot="singleLabel" slot-scope="{ option }"
                  ><strong>{{ option.prestname }}</strong></template
                >
              </multiselect>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-4">
              <label for="">Date ODS <i v-b-tooltip.hover.right="'Date où l\'éxecution commence'" class="fas fa-info-circle text-info"></i></label>
              <input
                type="date"
                class="form-control"
                v-model="ODS_info.date_ODS"
                :disabled="!disabled"
                :class="{ 'is-invalid': $v.ODS_info.date_ODS.$error }"
              />
              <div v-if="submitted && $v.ODS_info.date_ODS.after">
                <span class="text-danger" v-if="!$v.ODS_info.date_ODS.after"
                  >la date doit être superieure ou égal à aujourd'hui</span
                >
              </div>
            </div>
            <div class="col-lg-4">
              <label for="">Date éxecution <i v-b-tooltip.hover.right="'Date où l\'éxecution prendra fin'" class="fas fa-info-circle text-info"></i></label>
              <input
                type="date"
                class="form-control"
                v-model="ODS_info.date_exec_ODS"
                :disabled="!disabled"
                :class="{ 'is-invalid': $v.ODS_info.date_exec_ODS.$error || ODS_info.date_exec_ODS > selectedContract.end_date }"
                @input="reformDat"
              />
              <div v-if="submitted && $v.ODS_info.date_exec_ODS.after">
                <span
                  class="text-danger"
                  v-if="!$v.ODS_info.date_exec_ODS.after"
                  >la date doit être superieure ou égal à aujourd'hui</span
                >
                
              </div>
              <div v-if="ODS_info.date_exec_ODS > selectedContract.end_date" class="text-danger">
                  <span
                  class="text-danger"
                  v-if="ODS_info.date_exec_ODS > selectedContract.end_date"
                  >la date doit être inferieure à la date de fin de contract </span
                >
              </div>
            </div>

            <div class="col-lg-4">
                <label for="">Type de Service *</label>
                <multiselect v-model="selectedServices" @select="getServicesID" :deselect-label="''" :disabled="!disabled" :class="{'bg-light':!disabled,'disabledMS':!disabled}" :multiple="true"  track-by="id" label="designation" placeholder="" :options="operations" :searchable="false" :allow-empty="false">
                <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.designation }}</strong></template>
                </multiselect>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-md-12">
              <div class="form-group">
                <label for="formrow-inputCity">Commentaires</label>
                <textarea
                  v-model="ODS_info.comments"
                  class="form-control"
                  cols="30"
                  rows="1"
                  required
                  :disabled="!disabled"
                ></textarea>
              </div>
            </div>
          </div>

          <!-- end row -->
        </div>
        <!-- end card-body -->
      </div>
      <div class="p-3">
        <div class="row">
          <div class="col">
            <h5 class="mb-2">
              Prestations:
              <a href="javascript:void(0)" @click="getHostingServices"
                >Générer</a
              >
            </h5>
          </div>
        </div>

        <div class="row mt-2" v-for="(roomType, i) in HostingService" :key="i">
          <div class="col">
            <rooms
              :disable="disabled"
              :key="roomType"
              :pos="roomType"
              :service="filteredHostingService"
              :period="diffDays"
              @getvalues="UsedRooms[`${roomType}`] = $event"
              @getvaluesEmitted="getEmit = $event"
            />
            <!-- <repas :estimated="estimated" :disable="disabled" :key="rep" :pos="rep" :service="filteredHostingService" :send="send" @getvalues="Repas[`${rep}`] = $event" @getvaluesEmitted="getEmit = $event" /> -->
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col text-right mr-2 mb-3" v-if="add">
          <b-button
            :disabled="$v.ODS_info.$invalid || disableButtons || ODS_info.date_exec_ODS > selectedContract.end_date || HostingService.length == 0 || ODS_info.status=='approved'"
            @click="onComplete"
            variant="primary"
            class="btn-label mr-2"
          >
            <i class="far fa-save text-white label-icon"></i> Enregitrer
          </b-button>

          <b-button
            @click="resetForm = true"
            variant="light"
            class="btn-label"
          >
            <i class="fas fa-times-circle text-danger label-icon"></i> Annuler
          </b-button>
        </div>

        <div class="col text-right mr-2 mb-3" v-if="edit || ODS_info.id">
          <b-button @click="onUpdate" :disabled="disableButtons || ODS_info.date_exec_ODS > selectedContract.end_date" variant="primary" class="btn-label mr-2">
            <i class="far fa-save text-white label-icon"></i> Enregitrer
          </b-button>

          <b-button
            @click="resetForm = true"
            variant="light"
            class="btn-label"
          >
            <i class="fas fa-times-circle text-danger label-icon"></i> Annuler
          </b-button>
        </div>
      </div>
    </div>
  </div>
  <!-- </Layout> -->
</template>
